import React from 'react'
import classNames from 'classnames'

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Button from '@bit/azheng.joshua-tree.button';
import ImageMeta from '../ImageMeta'

const TextOverlapImage = ({ heading, text, image, button, reverse }) => {

    const mainClasses = classNames({
        "text-overlap-image": true,
        "flex-row-reverse": reverse
    })
    return (
        <div className={mainClasses}>
            <div className="text-overlap-image__image">
                <ImageMeta
                    cloudName="nuvolum"
                    publicId={image}
                    responsive
                    width="auto"
                />
            </div>
            <div className="text-overlap-image__content">
                <h2>{heading}</h2>
                <MarkdownViewer markdown={text} />
                <Button 
                    href={button.href}
                    buttonText={button.buttonText}
                    contained={button.appearance === "contained"}
                />
            </div>
        </div>
    )
}

export default TextOverlapImage
