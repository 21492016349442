import React from "react"
import ImageMeta from "../../components/ImageMeta"
import MarkdownViewer from "../../../bit-components/markdown-viewer/dist/MarkdownViewer"

function OurMission(props) {
  return (
    <>
      <div className="our-mission-stripes">
        <ImageMeta
          cloudName="nuvolum"
          publicId="FLSDE/DEV/flsde-segmental-line"
          width="auto"
          responsive
        />
      </div>

      {/* <div className="gradient-divider"></div> */}

      <section className="our-mission">
        <div
          style={{ width: "100%" }}
          className="columns has-text-centered-tablet">
          <div className={`column is-${props.sideColumnIs}`} />
          <MarkdownViewer markdown={props.post.ourMission} />
          <div className={`column is-${props.sideColumnIs}`} />
        </div>
      </section>

      {/* <div className="gradient-divider"></div> */}

      <div className="our-mission-stripes">
        <ImageMeta
          cloudName="nuvolum"
          publicId="FLSDE/DEV/flsde-segmental-line"
          width="auto"
          responsive
        />
      </div>
    </>
  )
}

export default OurMission
